<template>
    <div class="purchase-price">
        <el-form class="page-search" :inline="true">
            <div class="left">
                <el-form-item>
                    <el-input
                        v-model="searchForm.skuTitle"
                        placeholder="货品名称"
                        clearable
                        @keyup.native.enter="querydata"
                        @clear="querydata"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.brandId"
                        placeholder="选择品牌"
                        clearable
                        filterable
                        @change="querydata"
                    >
                        <el-option
                            v-for="item in brands"
                            :key="item.id"
                            :label="item.brandName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-select
                        v-model="searchForm.warehouseId"
                        placeholder="请选择仓库"
                        clearable
                        filterable
                        @change="querydata"
                    >
                        <el-option
                            v-for="item in warehouseList"
                            :key="item.warehouseId"
                            :label="item.warehouseName"
                            :value="item.warehouseId"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-date-picker
                        v-model="dateRange"
                        style="width: 3.2rem;"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="采购开始日期"
                        end-placeholder="采购结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :clearable="false"
                        @change="querydata"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-input
                        v-model="searchForm.orderNo"
                        placeholder="最近一次采购单号"
                        clearable
                        @keyup.native.enter="querydata"
                        @clear="querydata"
                    ></el-input>
                </el-form-item>
                <!-- <div class="hidden" v-if="conceal"> -->
                <el-form-item>
                    <el-select
                        v-model="searchForm.vendorId"
                        placeholder="供应商"
                        clearable
                        filterable
                        @change="querydata"
                    >
                        <el-option
                            v-for="item in vendors"
                            :key="item.id"
                            :label="item.vendorName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- </div> -->
            </div>
            <div class="right">
                <div class="search-btn">
                    <!-- <div class="more" @click="conceal = !conceal">
                        <span v-show="!conceal">展开</span>
                        <span v-show="conceal">收起</span>
                        <i v-show="!conceal" class="el-icon-arrow-down"></i>
                        <i v-show="conceal" class="el-icon-arrow-up"></i>
                    </div> -->
                    <div class="custom-btn">
                        <el-button
                            type="primary"
                            @click="querydata"
                        >
                            查询
                        </el-button>
                        <el-button @click="reset">重置</el-button>
                    </div>
                </div>
            </div>
        </el-form>
        <div class="page-content">
            <!-- <div class="page-handle-btn">
                <span @click="handleClick">
                    <i class="iconfont">警戒值配置</i>
                </span>
            </div> -->
            <div class="gb-table">
                <el-table
                    ref="table"
                    :data="tableData"
                    height="500"
                    row-key="id"
                >
                    <el-table-column type="index">
                        <template slot="header">
                            <i
                                class="el-icon-s-tools"
                                @click="showColumnSortDia"
                            ></i>
                        </template>
                        <template slot-scope="scope">
                            <span>{{
                                (searchForm.pageIndex - 1) *
                                    searchForm.pageSize +
                                    scope.$index +
                                    1
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        v-for="(column, index) in activeColumns"
                        :key="index"
                        :prop="column.prop"
                        :label="column.label"
                        :min-width="column.minWidth"
                        :formatter="column.formatter"
                        :align="column.align ? column.align : 'center'"
                        :show-overflow-tooltip="column.showTip"
                    >
                    </el-table-column>
                    <!-- <el-table-column
                        label="操作"
                        fixed="right"
                        :min-width="150"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-button type="text">编辑</el-button>
                            <el-button type="text">删除</el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
                <el-pagination
                    v-if="tableData && tableData.length"
                    :current-page="searchForm.pageIndex"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="searchForm.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    @current-change="handlesCurrentChange"
                    @size-change="handleSizeChange"
                ></el-pagination>
            </div>
        </div>
        <column-sort-dia
            ref="columnSortDia"
            :columns="columns"
            @column-change="columnChange"
        ></column-sort-dia>
    </div>
</template>

<script>
import columnSortDia from '@/components/columnSortDialog'
import _ from 'lodash'
import utilsTable from '@/utils/table'
import productSvc from 'services/product'
import tradeSvc from 'services/trade'
export default {
    name: 'PurchaseFollow',
    components: { columnSortDia },
    data () {
        return {
            /**
             *  hidden: false, 是否隐藏列
             *  showTip: true, 文字过长悬浮提示
             */
            columns: [
                {
                    prop: 'skuTitle',
                    label: '货品名称',
                    hidden: false
                },
                {
                    prop: 'brandName',
                    label: '品牌',
                    hidden: false
                },
                {
                    prop: 'warehouseName',
                    label: '仓库',
                    hidden: false
                },
                {
                    prop: 'historyAvgPrice',
                    label: '历史平均采购价',
                    formatter: this.price,
                    hidden: false
                },
                {
                    prop: 'increaseRate',
                    label: '采购价涨幅',
                    formatter: this.percent,
                    hidden: false
                },
                {
                    prop: 'price',
                    label: '最新采购价',
                    formatter: this.price,
                    hidden: false
                },
                {
                    prop: 'receiveTime',
                    label: '采购时间',
                    formatter: this.date,
                    hidden: false
                },
                {
                    prop: 'orderNo',
                    label: '采购单号',
                    hidden: false
                },
                {
                    prop: 'vendorName',
                    label: '供应商简称',
                    hidden: false
                },
                {
                    prop: 'lastPrice',
                    label: '上一次采购价',
                    formatter: this.price,
                    hidden: false
                },
                {
                    prop: 'lastReceiveTime',
                    label: '上一次采购时间',
                    formatter: this.date,
                    hidden: false
                },
                {
                    prop: 'lastOrderNo',
                    label: '上一次采购单号',
                    hidden: false
                },
                {
                    prop: 'lastVendorName',
                    label: '上一次采购供应商',
                    hidden: false
                }
            ],
            searchForm: {
                pageIndex: 1,
                pageSize: 20
            },
            warehouseList: [],
            total: null,
            tableData: [],
            brands: [],
            dateRange: [],
            vendors: [],
            conceal: false
        }
    },
    computed: {
        activeColumns () {
            return this.columns.filter(item => !item.hidden)
        },
        startTime () {
            return this.dateRange && this.dateRange[0]
                ? this.dateRange[0]
                : null
        },
        endTime () {
            return this.dateRange && this.dateRange[1]
                ? this.dateRange[1]
                : null
        }
    },
    created () {
        this.getWarehouseList()
        this.getTableData()
        this.getVendors()
        this.getBrands()
    },
    methods: {
        ...utilsTable,
        async getVendors () {
            try {
                const { data = [] } = await tradeSvc.getVendors()
                this.vendors = data
            } catch (error) {
                console.log(error)
            }
        },
        async getWarehouseList () {
            try {
                this.loading = true
                const { data = [] } = await tradeSvc.getWarehouseList()
                this.warehouseList = data
            } catch (error) {
                console.log(error)
            }
        },
        async getTableData () {
            this.searchForm.startPurchaseTime = this.startTime
            this.searchForm.endPurchaseTime = this.endTime
            const { data = {} } = await tradeSvc.getPurchasePriceList(
                this.searchForm
            )
            this.tableData = data.items
            this.total = Number(data.total)
        },
        async getBrands () {
            try {
                const { data = [] } = await productSvc.getBrands()
                this.brands = data
            } catch (error) {
                console.log(error)
            }
        },
        handleClick () {
            // this.$message.warning
        },
        showColumnSortDia () {
            this.$refs.columnSortDia.visible = true
        },
        columnChange (val) {
            this.columns = _.cloneDeep(val)
            this.tableReLoayout('table')
        },
        querydata () {
            console.log(this.searchForm)
            this.searchForm.pageIndex = 1
            this.getTableData()
        },
        handlesCurrentChange (val) {
            this.searchForm.pageIndex = val
            this.getTableData()
        },
        handleSizeChange (val) {
            this.searchForm.pageIndex = 1
            this.searchForm.pageSize = val
            this.getTableData()
        },
        reset () {
            this.dateRange = []
            this.searchForm = {
                pageSize: 20,
                pageIndex: 1
            }
            this.getTableData()
        }
    }
}
</script>
<style lang="scss" scoped>
.purchase-price {
    .el-icon-s-tools {
        cursor: pointer;
    }
}
</style>
